import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import get from 'lodash/get';

import Layout from '../layouts';
import LoginPageBody from '../components/LoginPageBody';
import UpdatePasswordBody from '../components/UpdatePasswordBody';

class UpdatePasswordPage extends React.Component {
  render() {
    const { gatsbyUrl, pythonUrl, brand, invisibleRecaptchaSiteKey } = get(this.props, 'data.site.siteMetadata');
    const { location } = this.props;

    return (
      <Layout location={location}>
        <LoginPageBody brand={brand}>
          <UpdatePasswordBody
            gatsbyUrl={gatsbyUrl}
            pythonUrl={pythonUrl}
            location={location}
            sitekey={invisibleRecaptchaSiteKey}
            brand={brand}
          />
        </LoginPageBody>
      </Layout>
    );
  }
}

const pageQuery = graphql`
  query UpdatePasswordPageQuery {
    site {
      siteMetadata {
        title
        gatsbyUrl
        pythonUrl
        brand {
          name
          nameCom
          twitter
          facebook
        }
      }
    }
  }
`;

export default ({ location }) => (
  <StaticQuery
    query={pageQuery}
    render={data => <UpdatePasswordPage data={data} location={location} />}
  />
);
