import React from 'react';
import { Link } from 'gatsby';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import trim from 'lodash/trim';
import { withNamespaces } from 'react-i18next';
import getQueryParam from '../utils/getQueryParam';
import s from './LoginForm.module.scss';
import Api from '../utils/api';
import BackendErrors from '../utils/backendErrors';
import GlobalError from './GlobalError';
import FiledError from './FieldError';
import backArrow from '../assets/img/round-chevron-right-24-px.svg';
import Grid from './Grid';
import Cell from './Cell';
import InputFiled from './InputFiled';
import ConfirmText from './ConfirmText';
import PageHeaderTitle from './PageHeaderTitle';

class UpdatePasswordBody extends React.Component {
  constructor(props) {
    super(props);

    this.errorMessages = {
      isEmpty: props.t('page_update_password:enter_password'),
      notEq: props.t('page_update_password:passwords_not_match'),
      generalError: props.t('core:general_error'),
      tooShortPassword: props.t('page_update_password:password_to_short'),
    };

    this.api = new Api({
      gatsbyUrl: props.gatsbyUrl,
      pythonUrl: props.pythonUrl,
      i18n: props.i18n,
    });
    this.backendErrors = new BackendErrors(this.api, props.i18n);

    this.onConfirmPasswordInput = this.onConfirmPasswordInput.bind(this);
    this.onConfirmPasswordBlur = this.onConfirmPasswordBlur.bind(this);
    this.onConfirmPasswordFocus = this.onConfirmPasswordFocus.bind(this);
    this.onPasswordInput = this.onPasswordInput.bind(this);
    this.onPasswordBlur = this.onPasswordBlur.bind(this);
    this.onPasswordFocus = this.onPasswordFocus.bind(this);
    this.onShowConfirmPasswordClick = this.onShowConfirmPasswordClick.bind(this);
    this.onShowPasswordClick = this.onShowPasswordClick.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  state = {
    password: {
      value: '',
      isValid: false,
      isTouched: false,
      errorMsg: [''],
      show: false,
      error: false,
      isFocused: false,
      showValidationMessage: false,
    },
    confirmPassword: {
      value: '',
      isTouched: false,
      errorMsg: [''],
      showError: false,
      show: false,
      isFocused: false,
      showValidationMessage: false,
    },
    isFormValid: false,
    isFormSubmitted: false,
    isPasswordVisible: false,
    isConfirmPasswordVisible: false,
    isSubmitSuccess: false,
    errorMsg: '',
    disableValidationMessage: true,
  };

  onSubmitForm(e) {
    e.preventDefault();

    const { password, confirmPassword } = this.state;
    const { location } = this.props;
    const validPassword = (
      password.value === confirmPassword.value
      && password.value.length >= 6
    );

    let passwordField = {
      isTouched: true,
      showValidationMessage: true,
      valid: false,
    };

    if (!validPassword) {
      if (isEmpty(password.value)) {
        passwordField = merge({}, passwordField, { errorMsg: [this.errorMessages.tooShortPassword] });
      }

      this.setState(state => merge(state, {
        disableValidationMessage: false,
        confirmPassword: { isTouched: true },
        password: passwordField,
      }));
      return;
    }

    const form = new FormData();
    const token = getQueryParam('token', location.search);

    form.append('password', password.value);
    form.append('token', token);

    const logoutConfig = this.api.logoutConfig();
    const updatePasswordConfig = this.api.updatePasswordConfig({ data: form });

    this.setState(state => merge(state, { isFormSubmitted: true }));

    const self = this;
    axios(updatePasswordConfig).then(response => {
      const stateUpdate = {
        errorMsg: '',
        isSubmitSuccess: true,
      };

      if (response.data.status === 'ok') {
        this.setState(oldState => merge({}, oldState, stateUpdate));
        axios(logoutConfig);
      }
    }).catch(error => {
      if (!error.response) {
        this.setState(state => merge(state, {
          errorMsg: {
            general: [self.errorMessages.generalError],
          },
        }, { password: { error: true } }));
      } else {
        const formErrors = error.response.data.errors;
        this.backendErrors.errors2messages(formErrors);
        this.setState(state => merge(state, {
          errorMsg: formErrors,
        }));
      }
    });
  }

  onShowPasswordClick() {
    const { password } = this.state;

    this.setState(state => merge(state, { password: { show: !password.show } }));
  }

  onShowConfirmPasswordClick() {
    const { confirmPassword } = this.state;

    this.setState(state => merge(state, { confirmPassword: { show: !confirmPassword.show } }));
  }

  onConfirmPasswordInput(e) {
    const { value } = e.target;

    this.setState(s => merge(s, { confirmPassword: { value } }));
  }

  onConfirmPasswordBlur(e) {
    const { value } = e.target;

    this.setState(state => {
      const isPasswordsMatch = value === state.password.value;
      const isPasswordEmpty = state.password.value === '';

      let showConfirmError = !isPasswordsMatch;

      if (isPasswordEmpty) {
        showConfirmError = false;
      }

      const errorMsg = isPasswordsMatch
        ? [this.errorMessages.isEmpty]
        : [this.errorMessages.notEq];

      return merge(state, {
        confirmPassword: {
          isTouched: true,
          showValidationMessage: true,
          errorMsg,
          showConfirmError,
        },
      });
    });
  }

  onConfirmPasswordFocus(e) {
    this.setState(oldState => merge(oldState, {
      confirmPassword: {
        isTouched: true,
        isFocused: true,
        showValidationMessage: false,
      },
    }));
  }

  onPasswordBlur(e) {
    const { value } = e.target;
    const { i18n } = this.props;

    this.setState(state => {
      const isPasswordsMatch = value === state.confirmPassword.value;
      const isPasswordEmpty = trim(value) === '';
      const isPasswordShort = value.length !== 0 && value.length < 6;

      let showConfirmError = !isPasswordsMatch;
      let errorMsg;

      if (isPasswordEmpty) {
        showConfirmError = false;
      }

      const isValid = (
        !isPasswordShort
        && !isPasswordEmpty
        && isPasswordsMatch
      );

      if (isValid) {
        errorMsg = [''];
      } else {
        errorMsg = isPasswordsMatch
          ? [this.errorMessages.isEmpty]
          : [this.errorMessages.notEq];
      }

      return merge(state, {
        disableValidationMessage: false,
        password: {
          isValid,
          isFocused: false,
          showValidationMessage: true,
        },
        confirmPassword: { showConfirmError, errorMsg },
      });
    });

    if (value !== '') {
      this.setState(state => {
        if (value.length < 6) {
          return merge(state, {
            disableValidationMessage: false,
            password: {
              showValidationMessage: true,
              isValid: false,
              errorMsg: i18n.t('page_update_password:password_to_short'),
            },
          });
        }

        return merge(state, {
          disableValidationMessage: false,
          password: {
            showValidationMessage: true,
            isValid: true,
            errorMsg: [''],
          },
        });
      });
    } else {
      this.setState(state => merge(state, {
        disableValidationMessage: false,
        password: {
          showValidationMessage: true,
          isValid: false,
          errorMsg: this.errorMessages.isEmpty,
        },
      }));
    }
  }

  onPasswordFocus() {
    this.setState({
      password: {
        isTouched: true,
        isFocused: true,
        showValidationMessage: false,
      },
    });
  }

  onPasswordInput(e) {
    const { value } = e.target;

    this.setState(s => merge(s, { password: { value, isTouched: true } }));
  }

  render() {
    const { isFormSubmitted, isSubmitSuccess, password, confirmPassword, errorMsg, disableValidationMessage } = this.state;
    const { i18n, t, brand } = this.props;
    const forgotPasswordUrl = this.api.forgotPasswordUrlAbs();

    return (
      <Grid>
        <PageHeaderTitle brandName={brand.name} title={t('page_update_password:update_password')} />
        <Cell />
        <Cell style={{ padding: '0 24px' }} desktop={12} tablet={8} phone={4} align='middle'>
          <form onSubmit={this.onSubmitForm} data-test='up-form'>
            <header className={s.header} data-test='up-form-header'>
              {isSubmitSuccess
                ? i18n.t('page_update_password:password_updated')
                : i18n.t('page_update_password:update_password')
              }
            </header>
            {isFormSubmitted
              ? <GlobalError data-test='up-global-err' errors={errorMsg} redirect_url={forgotPasswordUrl} />
              : <div />
            }
            {isSubmitSuccess
              ? (
                <ConfirmText data-test='up-confirm' text={i18n.t('page_update_password:password_changed')} />
              ) : (
                <section>
                  <div>
                    <div className={s.field}>
                      <label className={s.label} htmlFor='password'>
                        {i18n.t('page_update_password:new_password')}
                      </label>
                      <div className={s.toggleWrapper}>
                        <InputFiled
                          name='password'
                          id='password'
                          hasError={
                            password.showValidationMessage
                            && !password.isValid
                          }
                          type='password'
                          onInput={this.onPasswordInput}
                          onBlur={this.onPasswordBlur}
                          onFocus={this.onPasswordFocus}
                          data-test='up-password-input'
                        />
                      </div>
                      {disableValidationMessage ? (
                        <div className={s.fieldwarn} data-test='up-filed-error-password'>
                          {i18n.t('page_update_password:password_to_short')}
                        </div>
                      ) : (
                        <div className={!password.isValid ? s.fielderr : s.fieldwarn} data-test='up-filed-error-password'>
                          {password.errorMsg}
                        </div>
                      )}
                    </div>
                    <div className={s.field}>
                      <label className={s.label} htmlFor='confirm-password'>
                        {i18n.t('page_update_password:confirm_password')}
                      </label>
                      <div className={s.toggleWrapper}>
                        <InputFiled
                          name='confirm-password'
                          id='confirm-password'
                          hasError={
                            confirmPassword.showValidationMessage
                            && confirmPassword.showConfirmError
                          }
                          type='password'
                          onInput={this.onConfirmPasswordInput}
                          onBlur={this.onConfirmPasswordBlur}
                          onFocus={this.onConfirmPasswordFocus}
                          data-test='up-confirm-password-input'
                        />
                      </div>
                      { confirmPassword.showValidationMessage
                        && confirmPassword.showConfirmError
                        && <FiledError data-test='up-field-err-confirm-password' errorMsg={confirmPassword.errorMsg} />
                      }
                    </div>
                  </div>
                  <button data-test='up-submit' className={s.loginBtn} type='submit' color='primary'>
                    {i18n.t('page_update_password:set_new_password')}
                  </button>
                </section>
              )
            }
            <hr className={s.hr} />
            <Link
              to={this.api.loginUrl()}
              className={`${s.noAccount} ${s.bottomLink}`}
              data-test='link-to-login'
            >
              <div className={s.backArrow}>
                <img src={backArrow} />
              </div>
              <span>
                {i18n.t('page_update_password:back_to_login')}
              </span>
            </Link>
          </form>
        </Cell>
        <Cell />
      </Grid>
    );
  }
}

export default withNamespaces()(UpdatePasswordBody);
