const getQueryParam = (name, url) => {
  const regexName = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${regexName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);

  if (!results) {
    return null;
  }

  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export default getQueryParam;
